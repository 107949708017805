import student1 from "../../images/students-1.png";
import student2 from "../../images/students-2.png";
import student3 from "../../images/students-3.png";

export const ReviewData = [
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student1,
  },
  {
    name: "Sunita Nilson",
    title: "Great and memorable experience .",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "Arts Student, Gujarat University",
    img: student2,
  },
  {
    name: "Deepak Kumar Jha",
    title: "Always believed in helping & guiding",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "BCA Student, Sharda University",
    img: student3,
  },
  {
    name: "Anonymous",
    title: "warm, friendly atmosphere.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "Arts Student, Gujarat University",
    img: student1,
  },
  {
    name: "Cindy Martinez",
    title: "Definitely the right choice for him",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "BCA Student, Sharda University",
    img: student2,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student3,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student1,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student3,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student2,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student3,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student2,
  },
  {
    name: "Arjun Pandit",
    title: "I must say I am very impressed.",
    message:
      "Lorem Ipsum is it simply dummy text of the printing and it is typesetting industry. Lorem Ipsum it is has been the industery standard dummy text ever since",
    clg: "MBA Student, Leeds University",
    img: student1,
  },

];
